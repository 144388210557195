import { ChatText } from "phosphor-react";
import React from "react";
import { trackReportIssueClicked } from "analytics/issues/report-issue-analytics";
import { useModalManager } from "../../../hooks/use-modal-manager";

type Props = {};

const ReportIssue = ({}: Props) => {
    const { openModal } = useModalManager();

    return (
        <>
            <div
                className="inline-flex items-center justify-start cursor-pointer text-smallLH20 hover:underline"
                onClick={() => {
                    openModal("report-issue");
                    trackReportIssueClicked();
                }}
            >
                <ChatText
                    weight="regular"
                    className="text-secondary mr-1"
                    size={18}
                    data-testid="reportIcon"
                />
                Report an issue
            </div>
        </>
    );
};

export default ReportIssue;
