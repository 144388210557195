export const ACL_ROLES_GROUPS = {
    Admin: "app-acl-admin"
};

export enum userRoles {
    ADVISOR = "advisor",
    MANAGER = "manager",
    SUPERADMIN = "superadmin",
    SCOUT = "scout"
}
