import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import { ApiSelectOption } from "api/common/ApiSelectOption";
import { CurrenciesList } from "types/index";

export type BookingsStoreState = {
    backUrl: string;
    data: any[];
    filters: any;
    currentSupplierId: string /*it replace the router query supplier type identification*/;
    jumpVisibilityState: boolean;
    initLoadingHotelComponent: boolean;
    hiddenList: boolean;
    currenciesList: CurrenciesList["data"];
};

export type BookingsStoreActions = {
    setBackUrl: (value: string) => void;
    setData: (value: any[]) => void;
    setFilters: (value: any) => void;
    setCurrentSupplierId: (value: string) => void;
    setJumpVisibilityState: (value: boolean) => void;
    setInitLoadingHotelComponent: (value: boolean) => void;
    setHiddenList: (value: boolean) => void;
    clearStore: () => void;
    setCurrenciesList: CurrenciesList["setData"];
};

export const initialStateBookingsStore: BookingsStoreState = {
    backUrl: "",
    data: [],
    filters: {},
    currentSupplierId: "",
    jumpVisibilityState: false,
    initLoadingHotelComponent: false,
    hiddenList: false,
    currenciesList: []
};

export const useBookingsPlatformStore = create<
    BookingsStoreState & BookingsStoreActions
>()(
    devtools(
        persist(
            set => ({
                ...initialStateBookingsStore,
                setBackUrl: (value: string) =>
                    set((state: BookingsStoreState) => ({ backUrl: value })),
                setData: data => set((state: BookingsStoreState) => ({ data })),
                setFilters: filters =>
                    set((state: BookingsStoreState) => ({
                        filters: { ...state.filters, ...filters }
                    })),
                setCurrentSupplierId: (value: string) =>
                    set((state: BookingsStoreState) => ({
                        currentSupplierId: value
                    })),
                setJumpVisibilityState: (value: boolean) =>
                    set((state: BookingsStoreState) => ({
                        jumpVisibilityState: value
                    })),
                setInitLoadingHotelComponent: (value: boolean) =>
                    set((state: BookingsStoreState) => ({
                        initLoadingHotelComponent: value
                    })),
                setHiddenList: (value: boolean) =>
                    set((state: BookingsStoreState) => ({
                        hiddenList: value
                    })),
                setCurrenciesList: (value: ApiSelectOption[]) =>
                    set((state: BookingsStoreState) => ({
                        currenciesList: value
                    })),
                clearStore: () => set(initialStateBookingsStore)
            }),
            {
                name: "bookingsPlatform-store"
            }
        )
    )
);
