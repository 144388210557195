export const ROUTES = {
    Home: '/',
    Signin: '/auth/signin',
    Signout: '/auth/signout',
    Dashboard: '/dashboard',
    Commissions: '/business/commissions',
    Commission: '/business/commissions/'
};

export const API_ROUTES = {
    GetUser: '/api/user',
    GetToken: '/api/examples/jwt',
    GetBookings: '/api/business/commissions',
    GetBooking: '/api/business/commissions/',
    GetContacts: '/api/marketing/contacts',
    GetContact: '/api/marketing/contacts/',
    GetSuppliers: '/api/planning/suppliers',
    GetSupply: '/api/planning/supply',
};